import { h } from 'preact'
import CalculatorApp from 'common-modules/calculator'
import MainCalculatorFormView from './calculator-form'

export default class MainCalculatorApp extends CalculatorApp {
  public render() {
    return (
      <div id="preact_root">
        <div className="hero-form__holder">
          <MainCalculatorFormView
            currencies={this.currencies}
            routeFusionCurrencies={this.state.settings.routeFusionCurrencies}
            crownAgentsCurrencies={this.state.settings.crownAgentsCurrencies}
            errorStatus={this.state.errorStatus}
            debounceTime={this.debounceTime}
            updateErrorStatus={this.updateErrorStatus}
          />
        </div>
      </div>
    )
  }
}
