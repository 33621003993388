interface UrlParams {
  [key: string]: string
}

export const getCookieDomain = (domain?: string): string => {
  return (domain || location.hostname).replace(/^(en.)/, '')
}

const cookieService = {
  maximalCookieTTL: 90,
  setItem: (key: string, value: string, sameSite = 'None', days = 365, domain = ''): void => {
    const currentDate = new Date()
    currentDate.setTime(+currentDate + days * 86400000)
    document.cookie = `${key}=${value}; path=/; domain=${getCookieDomain(
      domain,
    )}; expires=${currentDate.toUTCString()}; SameSite=${sameSite}; Secure`
  },
  getItem: (name: string): string | null => {
    const parts = document.cookie ? document.cookie.split(`${name}=`) : []
    return parts.length === 2 ? parts.pop().split(';').shift() : null
  },
  removeItem(key: string): void {
    if (document.cookie) document.cookie = `${key}=; path=/`
  },
  removeCookie(key: string, domain?: string | null): void {
    if (document.cookie) {
      const zeroTime = new Date(0)
      document.cookie = `${key}=; expires=${zeroTime.toUTCString()}${domain ? `; domain=${domain}` : ''}; path=/`
    }
  },
  extractParamsFromURL: (): UrlParams => {
    const params = new URLSearchParams(window.location.search)
    const vars: UrlParams = {}
    params.forEach((value, key) => {
      vars[key] = value
    })
    return vars
  },
  setupCookiesFromURL: (allowedParamsIds: string[]): void => {
    const paramsToStore = cookieService.extractParamsFromURL()
    for (const paramName in paramsToStore) {
      if (Object.prototype.hasOwnProperty.call(paramsToStore, paramName) && allowedParamsIds.includes(paramName)) {
        let cookieName = paramName
        if (paramName === 'xchangerId') cookieName = 'xchanger_id'
        cookieService.setItem(cookieName, paramsToStore[paramName], 'Lax', cookieService.maximalCookieTTL)
      }
    }
  },
  setupCookiesFromClient: (): void => {
    if (cookieService.getItem('ik_referrer')) {
      // TODO: Check is this code relevant for us.
      cookieService.setItem('ik_referrer', document.referrer, 'None', 730)
    }
  },
}

export default cookieService
