import { h } from 'preact'

const InputFieldSpinner = () => (
  <div className="bem-text-input-outer-wrapper__right-overlay">
    <div className="bem-text-input-outer-wrapper__spinner-holder">
      <div className="bem-spinner-icon is-in-input-field spin-animation" />
    </div>
  </div>
)

export default InputFieldSpinner
