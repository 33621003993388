export default {
  calculator: {
    buyLabelName: 'mam',
    sellLabelName: 'otrzymam',
    currentRate: 'Aktualny kurs:',
    continueButton: 'Kontynuuj wymianę',
    miniChart: 'Mini-wykres pokazuje wahania średniego kursu z ostatnich 7 dni',
    sellTooltip:
      'To kurs sprzedaży - sprzedajemy Ci {{currency}} po {{rate}} PLN. Im niższy kurs tym bardziej korzystny dla Ciebie. Automatyczna aktualizacja co 10 sekund.',
    buyTooltip:
      'To kurs kupna -kupujemy od Ciebie {{currency}} po {{rate}} PLN. Im wyższy kurs tym bardziej korzystny dla Ciebie. Automatyczna aktualizacja co 10 sekund.',
  },
  preactSelect: {
    search: 'Szukaj...',
  },
  frontpageExitPopup: {
    accountExists: 'Wygląda na to, że masz już konto. Zniżkę wysyłamy dla nowych Klientów.',
    error: 'Wystąpił błąd',
  },
  emailValidator: {
    requiredField: 'Konieczne jest zaznaczenie tego pola',
    notEmptyField: 'To pole nie może być puste',
    minValue: 'Minimalna wartość to',
    maxValue: 'Makymalna wartość to',
    incorrectEmail: 'Podaj poprawny adres email',
    toShortPassowrd: 'Hasło jest za krótkie',
  },
  currencyAlert: {
    isLower: 'będzie niższy',
    isHigher: 'będzie wyższy',
    mustBeLessThan: 'Kurs musi być liczbą mniejszą od 99,9999',
    alertAlreadyExists: 'Alert kursowy dla podanego kursu już istnieje',
    maxAlerts: 'Nie możesz zapisać się na większą ilość alertów. (max. 10)',
    error: 'Coś poszło nie tak, spróbuj za chwilę.',
    alertHash: 'alert-walutowy',
  },
  ratesUpdate: {
    update: 'Trwa aktualizowanie kursów...',
    success: 'Następna aktualizacja kursu za',
    error: 'Nie udało się pobrać danych. Ponawiam za',
    timeUnit: 'sekund.',
  },
  currencies: {
    AED: 'Dirham ZEA',
    ARS: 'Peso argentyńskie',
    AUD: 'Dolar australijski',
    BDT: 'Taka bengalska',
    BGN: 'Lew bułgarski',
    BRL: 'Real brazylijski',
    CAD: 'Dolar kanadyjski',
    CHF: 'Frank szwajcarski',
    CLP: 'Peso chilijskie',
    CNY: 'Juan chiński',
    COP: 'Peso kolumbijskie',
    CZK: 'Korona czeska',
    DKK: 'Korona duńska',
    EGP: 'Funt egipski',
    EUR: 'Euro',
    FJD: 'Dolar fidżi',
    GBP: 'Funt brytyjski',
    GHS: 'Cedi ghańskie',
    HKD: 'Dolar hongkoński',
    HUF: 'Forint węgierski',
    ILS: 'Nowy szekel izraelski',
    INR: 'Rupia indyjska',
    JOD: 'Dinar jordański',
    JPY: 'Jen japoński',
    KWD: 'Dinar kuwejcki',
    LSL: 'Loti lesotyjskie',
    MAD: 'Dirham marokański',
    MXN: 'Peso meksykańskie',
    NOK: 'Korona norweska',
    NPR: 'Rupia nepalska',
    NZD: 'Dolar nowozelandzki',
    OMR: 'Rial omański',
    PEN: 'Sol peruwiański',
    PGK: 'Kina papuańska',
    PLN: 'Polski złoty',
    RON: 'Leja rumuńska',
    RUB: 'Rubel rosyjski',
    SAR: 'Rial saudyjski',
    SEK: 'Korona szwedzka',
    SGD: 'Dolar singapurski',
    SZL: 'Lilangeni',
    THB: 'Bat tajlandzki',
    TRY: 'Lira turecka',
    USD: 'Dolar amerykański',
    UYU: 'Peso urugwajskie',
    WST: 'Tala samoańska',
    ZAR: 'Rand południowoafrykański',
  },
  currenciesDopelniacz: {
    // AED: 'Dirhama ZEA',
    ARS: 'Peso argentyńskiego',
    AUD: 'Dolara australijskiego',
    // BDT: 'Taki banglijskiej',
    BGN: 'Lewa bułgarskiego',
    BRL: 'Reala brazylijskiego',
    CAD: 'Folara kanadyjskiego',
    CHF: 'Franka',
    // CNY: 'Juana chinśkiego',
    CZK: 'Korony czeskiej',
    DKK: 'Korony duńskiej',
    EGP: 'Funta egipskiego',
    EUR: 'Euro',
    FJD: 'Dolara fidżyjskiego',
    GBP: 'Funta',
    GHS: 'Cedi ghańskiego',
    HKD: 'Dolara hongkońskiego',
    // INR: 'Rupii indyjskiej',
    JOD: 'Dinara jordańskiego',
    KWD: 'Dinara kuwejckiego',
    LSL: 'Loti lesotyjskiej',
    MAD: 'Dirhama marokańskiego',
    MXN: 'Peso meksykańskiego',
    NOK: 'Korony norweskiej',
    NPR: 'Rupii nepalskiej',
    NZD: 'Dolara nowozelandzkiego',
    OMR: 'Riala omańskiego',
    PEN: 'Sol peruwiańskiego',
    PGK: 'Kiny papuaskiej',
    PLN: 'Złotego',
    RON: 'Leja rumuńskiego',
    RUB: 'Rubla rosyjskiego',
    SAR: 'Riala saudyjskiego',
    SEK: 'Korony szwedzkiej',
    SGD: 'Dolara singapurskiego',
    SZL: 'Lilangeni suazyjskiego',
    // THB: 'Bata tajlandzkiego',
    TRY: 'Liry tureckiej',
    USD: 'Dolara',
    // UYU: 'Peso urugwajskiego',
    WST: 'Tali samoańskiej',
    ZAR: 'Randa południowoafrykańskiego',
  },
  chart: {
    time: 'Czas',
    rate: 'Kurs',
    opening: 'Otwarcie',
    highest: 'Najwyższy',
    lowest: 'Najniższy',
    closing: 'Zamknięcie',
    monthNames: ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru'],
    dayNames: ['pon', 'wto', 'śro', 'czw', 'pią', 'sob', 'nie'],
  },
  landingForm: {
    show: 'Pokaż',
    hide: 'Ukryj',
    requiredField: 'Konieczne jest zaznaczenie tego pola',
    notEmptyField: 'To pole nie może być puste',
    incorrectEmail: 'Podaj poprawny adres email',
    toShortPassowrd: 'Hasło jest za krótkie',
    invalidPromocode: 'Promocja dostępna na tej stronie się skończyła - zarejestruj się przez stronę główną',
    emailAlreadyExists: 'Ten e&#8209;mail nie może być użyty do rejestracji',
    generalInvalid: 'Coś poszło nie tak, spróbuj ponownie później',
  },
}
