import { Component } from 'preact'
import settingsService from 'services/settings'
import { NetworkStatusType } from 'app-main/common/connection-error'
import checkNetworkQuality from 'services/slow-network'

export interface CalculatorAppState {
  settings: {
    symbolsSorted: string[]
    currenciesNotSupported: string[]
    routeFusionCurrencies: string[]
    crownAgentsCurrencies: string[]
  }
  errorStatus: NetworkStatusType | null
}

export default class CalculatorApp extends Component<{}, CalculatorAppState> {
  constructor(props) {
    super(props)

    this.state = {
      settings: {
        symbolsSorted: [],
        currenciesNotSupported: [],
        routeFusionCurrencies: [],
        crownAgentsCurrencies: [],
      },
      errorStatus: null,
    }
  }

  public render() {
    return null
  }

  public async componentDidMount() {
    try {
      const settings = await settingsService.fetchCalculatorSettings()
      const networkType = await checkNetworkQuality()
      this.updateErrorStatus(networkType)

      this.setState({
        settings: {
          symbolsSorted: settings.currency.symbols_sorted,
          currenciesNotSupported: settings['currencies-not-supported'],
          routeFusionCurrencies: settings['route-fusion'].currencies,
          crownAgentsCurrencies: settings['crown-agents'].currencies,
        },
      })
    } catch (error) {
      this.updateErrorStatus('settings-failed')

      console.error('Error:', error)
    }
  }

  public updateErrorStatus = async (error: NetworkStatusType): Promise<void> => {
    this.setState({ errorStatus: error })
  }

  public calculateDebounceTime = (networkType: NetworkStatusType): number => {
    return networkType === 'slow' ? 0 : networkType === 'unknown' ? 250 : 350
  }

  public removeNotSupportedCurrencies = (currenciesList: string[]): string[] => {
    const { currenciesNotSupported } = this.state.settings
    return currenciesList.filter((currency) => !currenciesNotSupported.includes(currency)) //IKMKTG-178 //IKMKTG-275
  }

  get debounceTime(): number {
    return this.calculateDebounceTime(this.state.errorStatus)
  }

  get currencies(): string[] {
    const { symbolsSorted } = this.state.settings
    return this.removeNotSupportedCurrencies(symbolsSorted)
  }
}
