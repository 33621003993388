import {
  ExchangeRatesChartDailyData,
  MarketBrief,
  MarketHistory,
  MarketHistorySampleSpan,
  MarketHistoryTimespan,
} from 'models/market'
import { promiseWrapper } from 'utils/promise'

declare global {
  interface Window {
    marketBrief: MarketBrief[]
    pairMarketBrief: MarketBrief
  }
}

declare let USER_BASE_URL_IK: string

const marketService = {
  fetchMatketHistory: (
    pair: string,
    timeSpan: MarketHistoryTimespan,
    sampleSpan: MarketHistorySampleSpan,
  ): Promise<MarketHistory> => {
    return promiseWrapper<MarketHistory, null>(
      `${USER_BASE_URL_IK}/api/public/marketHistory/${pair}/${timeSpan}/${sampleSpan}`,
      'GET',
    )
  },
  fetchMarketHistoryDaily: (pair: string): Promise<ExchangeRatesChartDailyData> => {
    return promiseWrapper<ExchangeRatesChartDailyData, null>(
      `${USER_BASE_URL_IK}/api/public/marketHistory/dailyFxData?pairs[]=${pair}`,
      'GET',
    )
  },
  fetchPairMarketBrief: (currency_pair: string): Promise<void> => {
    return promiseWrapper<MarketBrief, null>(`${USER_BASE_URL_IK}/api/public/marketBrief/${currency_pair}`, 'GET').then(
      (data: MarketBrief) => {
        window.pairMarketBrief = data
        window.dispatchEvent(new Event('pairMarketBrief fetched'))
      },
      () => {
        window.dispatchEvent(new Event('pairMarketBrief fetch fail'))
      },
    )
  },
  getMarketBrief: (): MarketBrief[] => window.marketBrief || [],
  fetchRates: (): Promise<void> => {
    return promiseWrapper<MarketBrief[], null>(`${USER_BASE_URL_IK}/api/public/marketBrief`, 'GET').then(
      (data: MarketBrief[]) => {
        window.marketBrief = data
        window.dispatchEvent(new Event('marketBrief fetched'))
      },
      () => {
        window.dispatchEvent(new Event('marketBrief fetch fail'))
      },
    )
  },
}

export default marketService
