import { getSafeI18nTextOrEmpty } from 'services/i18n-helper'
import { h, FunctionComponent } from 'preact'

export type NetworkStatusType = 'unknown' | 'no-network' | 'slow' | 'fast' | '429' | string

interface ConnectionErrorProps {
  status: NetworkStatusType
}

export const ConnectionError: FunctionComponent<ConnectionErrorProps> = ({ status }) => {
  const getStatus = (): string => {
    switch (status) {
      case 'slow':
        return getSafeI18nTextOrEmpty('networkErrorStatus.slow')
      case 'no-network':
        return getSafeI18nTextOrEmpty('networkErrorStatus.noNetwork')
      default:
        return getSafeI18nTextOrEmpty('networkErrorStatus.default')
    }
  }

  return <p className="hero-form__connection-error-message-form">{getStatus()}</p>
}
