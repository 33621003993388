declare let USER_BASE_URL_IK: string
declare global {
  interface Navigator {
    connection?: NetworkInformation
    mozConnection?: NetworkInformation
    webkitConnection?: NetworkInformation
  }

  interface NetworkInformation {
    onchange?: (event: Event) => void
    effectiveType?: '2g' | '3g' | '4g' | 'slow-2g'
    downlink?: number
    downlinkMax?: number
    rtt?: number
    saveData?: boolean
  }
}

const checkNetworkQualityWithFetch = async (url: string): Promise<string> => {
  const start = Date.now()
  let statusNetwork = 'no-network'

  try {
    const response = await fetch(url)
    const duration = Date.now() - start

    if (!response.ok) {
      return statusNetwork
    }

    statusNetwork = duration > 2000 ? 'slow' : 'fast'
  } catch (error) {
    console.error('Network request failed:', error)
  }

  return statusNetwork
}

const checkNetworkQuality = async (): Promise<string> => {
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection

  const noNetworkDownlink = 0
  const noNetworkRtt = 0
  const slowNetworkDownlink = 1.6
  const slowNetworkRtt = 300

  if (!connection) {
    // Support safari check network quality
    return checkNetworkQualityWithFetch(`${USER_BASE_URL_IK}/api/public/settings?keys[]=bank-transfer-countries`)
  }

  if (connection.downlink === noNetworkDownlink && connection.rtt === noNetworkRtt) {
    return 'no-network'
  }

  if (
    (connection.downlink < slowNetworkDownlink && connection.rtt > slowNetworkRtt) ||
    connection.effectiveType === '3g' ||
    connection.effectiveType === '2g' ||
    connection.effectiveType === 'slow-2g'
  ) {
    return 'slow'
  }

  return 'fast'
}

export default checkNetworkQuality
