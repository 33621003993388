import { CalculatorDirection } from 'models/calculator'
import { promiseWrapper } from 'utils/promise'

declare const USER_BASE_URL_IK: string

export interface CreditCalculatorResponse {
  creditProfit: string | null
  yearProfit: string | null
}

export interface CreditCalculatorPayload {
  swift: string
  amount: string
  years: string
}

export interface DirectExchangeCompareResult {
  bankExchangeAmount: string | null
  bankExchangeRate: string | null
  exchangeAmount: string
  exchangeRate: string
  minimalBenefitAmount: string | null
  profit: string | null
  profitYear: string | null
  transferFee: string | null
  rateTs: string
  eurEquivalent: string
  error?: 'NO_RATE_FOR_PAIR' | string
}

interface WbrCalculatorResponse {
  average_rate: string
  average_rate_ts: string
  base_currency: string
  bought_amount: string
  direction: string
  eur_equivalent: string
  final_rate: string
  pln_equivalent: string
  sold_amount: string
  sold_currency: string
  spread: string
  standard_rate: string
  transfer_fee: string
  used_discount: string | null
  used_points: string
}

export interface DirectExchangeCompare {
  result: DirectExchangeCompareResult
}

const directExchangeService = {
  getCompareResult: (
    direction: CalculatorDirection,
    amount: string,
    baseCurrency: string,
    counterCurrency: string,
    swift?: string,
  ): Promise<DirectExchangeCompare> => {
    const url = `${USER_BASE_URL_IK}/api/public/directExchangeCompare/${direction}/${amount}/${baseCurrency}/${counterCurrency}/${
      swift || ''
    }`
    return promiseWrapper<DirectExchangeCompare, never>(url, 'GET')
  },
  getCreditResult: (payload: CreditCalculatorPayload): Promise<CreditCalculatorResponse> => {
    const url = `${USER_BASE_URL_IK}/api/public/standingOrderCompare/${payload.amount}/CHF/${payload.swift}/${payload.years}`
    return promiseWrapper<CreditCalculatorResponse, never>(url, 'GET')
  },
  getWbrResult: (sold_currency, bought_currency, elementLink): Promise<WbrCalculatorResponse> => {
    const url = `${USER_BASE_URL_IK}/api/public/direct-exchange-calculate?sold_currency=${sold_currency}&bought_currency=${bought_currency}&${elementLink}`
    return promiseWrapper<WbrCalculatorResponse, never>(url, 'GET')
  },
}

export default directExchangeService
