import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { i18n } from 'i18n/i18n'

declare let LOGIN_BASE_URL_IK: string
declare let REGISTRY_URL: string

export interface MainCalculatorContinueButtonProps {
  hrefLink: string
}

const MainCalculatorContinueButton = ({ hrefLink }: MainCalculatorContinueButtonProps) => {
  const dropdown = useRef<HTMLDivElement>()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    document.addEventListener('click', closeDropdownOnOutside)

    return () => document.removeEventListener('click', closeDropdownOnOutside)
  }, [])

  const closeDropdownOnOutside = (event): void => {
    if (!dropdown.current.contains(event.target)) {
      setOpen(false)
    }
  }

  const toggleDropdown = (): void => {
    setOpen(!isOpen)
  }

  // TODO: for A/B testing IKWT-4203
  const shouldShowhrefLink = true

  return (
    <div className="hero-form__section-with-big-button">
      <div className="hero-form__section-with-big-button-inner">
        <div className="hero-form-dropdown-button" ref={dropdown}>
          <div className="hero-form-dropdown-button__button">
            {shouldShowhrefLink ? (
              <a href={hrefLink} className="btn is-big-in-hero-form" data-calc-submit data-track-click="anchor-button">
                {i18n('calculator.continueButton')}
                <span className="bem-icon is-double-arrow-right hero-form-dropdown-button__icon" />
              </a>
            ) : (
              <div className="btn is-big-in-hero-form" onClick={toggleDropdown}>
                {i18n('calculator.continueButton')}
                <span className="bem-icon is-double-arrow-right hero-form-dropdown-button__icon" />
              </div>
            )}
          </div>
          {isOpen && (
            <div className="hero-form-dropdown-button__menu">
              <div className="hero-form-dropdown-button__menu-inner">
                <div className="hero-form__login-question">
                  <div className="hero-form__final-buttons">
                    <a href={REGISTRY_URL} className="hero-form__big-button-link">
                      <button type="button" className="btn is-big-in-hero-form for-final-button btn--secondary">
                        Załóż darmowe konto
                      </button>
                    </a>
                    <a href={LOGIN_BASE_URL_IK} className="hero-form__big-button-link">
                      <button type="button" className="btn is-big-in-hero-form for-final-button btn--secondary">
                        Zaloguj się
                      </button>
                    </a>
                  </div>
                  <div className="hero-form__step-3-text is-below-buttons">
                    Sprawdź{' '}
                    <a href="#dlaczego-warto" className="bem-text-link is-bold">
                      dlaczego warto
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MainCalculatorContinueButton
